import React from 'react'
import { useParams } from 'react-router-dom'
import type { ArticleConfig, PageContext } from '@which/glide-ts-types'

import type { Author, HeroVideo, Tag, VideoSeo } from '../../../generated/frontend'
import type { PageParams } from '../../../routes'
import { useBaseMetaTags } from '../../../shared/hooks/useBaseMetaTags'
import { usePageProps } from '../../../shared/usePageProps'
import { generateSchemaFromSnippet } from '../../../shared/utils/get-schema-from-snippet'
import { getTimestampToIso } from '../../../shared/utils/timestamp-to-iso'
import type { ArticleBasePageData, ArticlePageVariantTypes } from '../article-page-types'
import { useArticlePageDataLayerItems } from './useArticlePageDataLayerItems'
import { useArticlePageDfpItems } from './useArticlePageDfpItems'

export const useArticlePageMetaTags = () => {
  const { articleType } = useParams<PageParams>()
  const { context } = usePageProps()
  const { getBaseMetaTags } = useBaseMetaTags()
  const { getDataLayerItems } = useArticlePageDataLayerItems()
  const { getDfpItems } = useArticlePageDfpItems()

  return {
    getPageMetaTags: ({
      meta,
      articleBodyTemplate,
      authors = [],
      tags = [],
      imageList = [],
      imageUrl,
      articleConfig,
      heroVideo,
      videosSeoData,
      twitterCard,
    }: ArticleMetaArgs) => [
      ...getBaseMetaTags({ meta, imageUrl, twitterCard, context }),
      ...getAuthorsMetaTag(authors),
      ...getKeywordsMetaTag(meta.keywords),
      ...getDataLayerItems({
        meta,
        articleBodyTemplate,
        articleType,
        authors,
        tags,
        articleConfig,
        heroVideo,
      }),
      ...getDfpItems({ meta, tags, heroVideo }),
      ...getSchemaScript({
        meta,
        authors,
        imageList,
        heroVideo,
        context,
        videosSeoData,
      }),
      <link rel="preconnect" href="https://securepubads.g.doubleclick.net" />,
    ],
  }
}

///////// IMPLEMENTATION /////////

type ArticleMetaArgs = {
  meta: ArticlePageVariantTypes['meta']
  articleBodyTemplate: Record<string, any>
  authors?: Author[]
  tags?: Tag[]
  imageList?: string[]
  imageUrl?: string
  twitterCard: 'summary_large_image'
  articleConfig: ArticleConfig
  heroVideo?: ArticleBasePageData['heroVideo']
  heroDimensions?: {
    width: string
    height: string
  }
  videosSeoData?: ArticleBasePageData['videosSeoData']
}

type MetaSchemaParams = {
  meta: ArticleMetaArgs['meta']
  authors: Author[]
  imageList?: string[]
  heroVideo?: ArticleMetaArgs['heroVideo']
  videosSeoData: ArticleMetaArgs['videosSeoData']
}

const getAuthorsMetaTag = (authors: Author[]) =>
  authors.length
    ? [<meta key="author" name="author" content={authors.map(({ name }) => name).join(', ')} />]
    : []

const getKeywordsMetaTag = (keywords: ArticleMetaArgs['meta']['keywords']) =>
  keywords?.length ? [<meta key="keywords" name="keywords" content={keywords.join(', ')} />] : []

const getSchemaScript = ({
  meta,
  authors,
  imageList,
  heroVideo,
  context,
  videosSeoData,
}: MetaSchemaParams & { context?: PageContext }) => {
  if (['news', 'reviews', 'money'].includes(context ?? '')) {
    return generateSchemaFromMeta({
      meta,
      authors,
      imageList,
      heroVideo,
      context,
      videosSeoData,
    })
  } else {
    return generateSchemaFromSnippet(meta.headCodeBlock, 'headCodeBlock')
  }
}

const generateSchemaFromMeta = ({
  meta,
  authors,
  imageList,
  heroVideo,
  context,
  videosSeoData,
}: MetaSchemaParams & { context?: PageContext }) => {
  const [author] = authors
  const heroVideoSeo = heroVideo?.seo || null
  const embeddedVideosSeo = videosSeoData || []

  const dateModified = getTimestampToIso(meta.updatedDateTime) || ''
  const datePublished = getTimestampToIso(meta.publishedDateTime) || ''

  const videosSchema = getVideosSchema(heroVideoSeo, embeddedVideosSeo)

  const schemas = meta
    ? [
        <script type="application/ld+json">{`
        {
          "@context": "http://schema.org",
          "@type": "${context === 'news' ? 'NewsArticle' : 'Article'}",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "${meta.canonical}"
          },
          "headline": "${meta.title}",
          "description": "${meta.desc}",
          ${imageList ? `"image": ${JSON.stringify(imageList.filter(Boolean), null, 2)},` : ''}
          ${
            author?.name
              ? `
          "author": {
            "@type": "Person",
            "name": "${author.name}",
            "url": "https://www.which.co.uk${
              author.slug === null ? '/about-which/who-we-are' : `/news/author/${author.slug}`
            }"
          },`
              : ''
          }
          "publisher": {
            "@type": "Organization",
            "name": "Which?",
            "logo": {
              "@type": "ImageObject",
              "url": "https://www.which.co.uk/favicon-512.png",
              "width": 512,
              "height": 512
            }
          },
          ${videosSchema}
          "datePublished": "${datePublished}",
          "dateModified": "${dateModified}"
        }
      `}</script>,
      ]
    : []

  if (meta.reviewSchema) {
    const reviewSchema = generateSchemaFromSnippet(meta.reviewSchema)

    return [...schemas, ...reviewSchema]
  }

  return schemas
}

const getVideosSchema = (
  heroVideoSeo: HeroVideo['seo'] | null,
  embeddedVideosSeo: ArticleMetaArgs['videosSeoData']
) => {
  const allVideosSeoData = [heroVideoSeo, ...(embeddedVideosSeo ?? [])].filter(
    Boolean
  ) as VideoSeo[]
  const videosCount = allVideosSeoData.length

  if (!videosCount) {
    return ''
  }

  // Single video article
  if (videosCount === 1) {
    const [videoSeo] = allVideosSeoData
    const singleVideoSchema = getSingleVideoSchema(videoSeo)

    return `"video": ${singleVideoSchema}`
  }

  // Multi video article
  const videosSchema = allVideosSeoData.reduce((schema, videoSeo) => {
    const videoSchema = getSingleVideoSchema(videoSeo)

    return `${schema} ${videoSchema}`
  }, '')

  return `"video": [${videosSchema.slice(0, videosSchema.length - 1)}],`
}

const getSingleVideoSchema = (videoSeo: VideoSeo) => `{
  "@type": "VideoObject",
  "@context": "https://schema.org",
  "name": "${videoSeo.name}",
  "description": "${videoSeo.description}",
  "thumbnailUrl": "${videoSeo.thumbnailUrl}",
  "uploadDate": "${videoSeo.uploadDate}",
  "dateModified": "${videoSeo.dateModified}",
  "dateCreated": "${videoSeo.dateCreated}",
  "embedUrl": "${videoSeo.embedUrl}",
  "contentUrl": "${videoSeo.contentUrl}",
  "duration": "${videoSeo.duration}"
},`

import { useParams } from 'react-router-dom'
import type { ArticleConfig, PageContext } from '@which/glide-ts-types'

import type { Author, Tag } from '../../../generated/frontend'
import type { ArticleType } from '../../../routes'
import type { PageParams } from '../../../routes'
import { useBackStack } from '../../../shared/BrowserBackStackProvider'
import { getDataLayerScripts } from '../../../shared/data-layer'
import { useFullUrl } from '../../../shared/hooks/useFullUrl'
import type { PageInfo } from '../../../shared/types/PageInfo'
import { usePageProps } from '../../../shared/usePageProps'
import { getUpdatedAtDate } from '../../../shared/utils/get-updated-at-date'
import { getPreviousUrl } from '../../../shared/utils/getPreviousUrl/getPreviousUrl'
import { removeFalsy } from '../../../shared/utils/remove-falsy'
import { removeUrlQueryStringAndFragment } from '../../../shared/utils/remove-url-query-string-and-fragment'
import type { ArticleBasePageData, ArticlePageVariantTypes } from '../article-page-types'
import { hasLockedTable } from '../utils/hasLockedTable'
import { useArticlePageDataLayer } from './useArticlePageDataLayer'

export const useArticlePageDataLayerItems = () => {
  const { getPageDatalayer } = useArticlePageDataLayer()
  const { collectionSlug } = useParams<PageParams>()
  const { context } = usePageProps()
  const { getFullUrl } = useFullUrl()
  const backStack = useBackStack()
  const previousUrl = getPreviousUrl({ backStack: backStack })

  return {
    getDataLayerItems({
      meta,
      articleBodyTemplate,
      articleType,
      authors,
      tags,
      articleConfig,
      heroVideo,
    }: DataLayerOptions) {
      const {
        dataLayer = [],
        taxonomyHierarchy,
        contentId,
        keywords,
        updatedDateTime,
        publishedDate,
        glideCurationDate,
        frequencyOfUpdate,
        significantUpdate,
        systemContentPublishedDate,
        systemContentUpdatedDate,
      } = meta || {}

      const pageInfo: PageInfo = {
        wcdPageUrl: removeUrlQueryStringAndFragment(getFullUrl()),
        vertical: taxonomyHierarchy?.vertical.slug,
        sub_vertical: taxonomyHierarchy?.subVertical?.slug,
        super_category: taxonomyHierarchy?.superCategory?.slug,
        category: taxonomyHierarchy?.category?.slug,
        content_id: contentId,
        author: authors.map(({ name }) => name).join(' | '),
        pageType: collectionSlug ? 'mpg-article' : 'article',
        tags: getDataLayerTags(tags, keywords, context as PageContext),
        keywords: keywords?.length ? keywords.join(' | ') : '',
        pageComponents: getPageComponentsText(articleBodyTemplate),
        contentPublishedDate: getUpdatedAtDate(publishedDate),
        ...(Boolean(heroVideo) && { isVideoArticle: true }),
        ...(updatedDateTime && { contentUpdatedDate: getUpdatedAtDate(updatedDateTime) }),
        systemContentPublishedDate: getUpdatedAtDate(systemContentPublishedDate),
        systemContentUpdatedDate: getUpdatedAtDate(systemContentUpdatedDate),
        glideCurationDate,
        frequencyOfUpdate,
        significantUpdate,
        ...(previousUrl && { previousWcdPageUrl: previousUrl }),
      }

      return [
        ...getDataLayerScripts([
          ...dataLayer,
          removeFalsy(getPageDatalayer(pageInfo, articleType, articleConfig)),
        ]),
      ]
    },
  }
}

///////// IMPLEMENTATION /////////

type DataLayerOptions = {
  meta: ArticlePageVariantTypes['meta']
  articleBodyTemplate: Record<string, any>
  articleType: ArticleType
  authors: Author[]
  tags: Tag[]
  articleConfig: ArticleConfig
  heroVideo?: ArticleBasePageData['heroVideo']
}

const getDataLayerTags = (tags: Tag[], keywords: string[], context: PageContext) => {
  const tagsAsString = tags?.map((tag) => tag.label).join(' | ') || ''
  const tagsFallback = context === 'news' ? '' : keywords.join(' | ')

  // News articles should fallback to empty string if tags are empty
  // Tags for all other article types have a keywords fallback
  return tags?.length ? tagsAsString : tagsFallback
}

const getPageComponentsText = (articleBodyTemplate: Record<string, any>) => {
  const jsonString = JSON.stringify(articleBodyTemplate)
  const foundComponentsText: string[] = []

  if (jsonString.includes('"component":"SmartProductPicker"')) {
    foundComponentsText.push('SPP')
  }

  if (jsonString.includes('"component":"CardedDynamicTable"')) {
    foundComponentsText.push('MLP')
  }

  if (jsonString.includes('"component":"SquirrelEmbed"')) {
    foundComponentsText.push('squirrel widget')
  }

  // NSTL: non-standard table locked
  if (hasLockedTable(jsonString)) {
    foundComponentsText.push('NSTL')
  }

  return foundComponentsText.join('|')
}
